import React from 'react';
import { PERIOD_STATE } from '../../js/PeriodsPool';
import Texts from '../../js/Texts';
import './MeetingOverlay.css';


const MeetingOverlay = ({state}) => {

  return (
    <div className="MeetingOverlay">
      {state === PERIOD_STATE.NOT_STARTED && <div className="text">{Texts.get("multiplayer_not_started")}</div>}
      {state === PERIOD_STATE.PAUSED && <div className="text">{Texts.get("multiplayer_paused")}</div>}
    </div>
  );
};

export default MeetingOverlay;
