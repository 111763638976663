
import { makeAutoObservable, reaction, toJS } from "mobx"
import CardsPool from "./CardsPool"
import Combinations from "./Combinations"
import DataLoading from "./DataLoading"
import PeriodsPool from "./PeriodsPool"

export const CARDS_STATE = {
  PICKABLE: "PICKABLE",
  TOO_COMPLEX: "TOO_COMPLEX",
  LOCKED: "LOCKED",
  PICKED_PREV: "PICKED_PREV",
  PICKED_NOW: "PICKED_NOW",
}


class Card {
  picked = false
  pickedIndex = null
  complexity = 0

  constructor (json) {
    Object.assign(this, json)

    this.id = this.id.toString() // j'ai eu des conversions sauvages en int!
    this.complexity = parseInt(this.complexity)
    this.service = parseInt(this.service)
    this.engagement = parseInt(this.engagement)
    this.efficiency = parseInt(this.efficiency)
    this.value = parseInt(this.value)
    this["impact-release-1"] = parseInt(this["impact-release-1"])
    this["impact-release-2"] = parseInt(this["impact-release-2"])



    if(this.prerequisites) this.prerequisites = this.prerequisites.split(";").map(pre => pre.trim())

    reaction( () => this.locked, () => {this.onLockedChange() })
    makeAutoObservable(this)
  }



  get locked() {
    if(!this.prerequisites) return false
    else {
      // Tous les prereq doivent être unlock afin d'unlock cette carte
      let unlocked = this.prerequisites.every(prereq_id => {
        let c = CardsPool.get(prereq_id)
        let val = (c && c.picked)
        return val
      })

      return !unlocked
    }
  }

  onLockedChange() {
    if(this.locked && this.picked !== false) {
      this.release()
    }
  }

  pick() {
    this.picked = PeriodsPool.currentPeriod.id
  }
  release() {
    this.picked = false
  }

  get Category() {
    let c = DataLoading.files.categories.find(c => c.id === this.category)
    if(!c) {
      console.log("CARD", this.id, "can't find categ", this.category)
    }
    return c
  }

  get categTitle() {
    let title = this.Category?.title
    return title || "ERROR CATEGORY NOT FOUND"
  }
  get categColor() {
    let color = this.Category?.color
    return color || "ERROR CATEGORY NOT FOUND"
  }

  get state() {
    // picked prviously
    if(this.locked) return CARDS_STATE.LOCKED
    if(this.pickedNow) return CARDS_STATE.PICKED_NOW
    if(this.tooComplex) return CARDS_STATE.TOO_COMPLEX
    if(this.pickedPrev) return CARDS_STATE.PICKED_PREV

    return CARDS_STATE.PICKABLE
  }

  get pickedPrev() {
    return this.picked !== false && this.picked !== PeriodsPool.currentPeriodIndex
  }

  get tooComplex() {
    return PeriodsPool.currentPeriod.complexity + this.complexity > PeriodsPool.currentPeriod.max_complexity
  }

  get currentImpact() {
    return this["impact-release-" + (PeriodsPool.currentPeriodIndex + 1)]
  }

  get pickedImpact() {
    if(this.picked !== false) return this["impact-release-" + this.picked]

    return null
  }

  get combinations() {
    return Combinations.currentCombinations[this.id]
  }

  get combinationValue() {
    let discount = 0
    if(this.combinations) {
      discount = this.combinations.reduce((acc, c) => {
        return acc + parseInt(c.value)
      }, 0)
    }

    return discount

  }

  get pickedNow() {
    return this.picked === PeriodsPool.currentPeriod.id
  }

  get details() {
    return [
      {id: "service", value: this.service },
      {id: "engagement", value: this.engagement },
      {id: "efficiency", value: this.efficiency },
      {id: "value", value: this.value }
    ]
  }
}
export default Card;
