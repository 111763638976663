import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AppState from "../../js/AppState";
import PeriodsPool from "../../js/PeriodsPool";
import Texts from "../../js/Texts";
import Header from "../Header/Header";
import { AppTitle, Button, IconButton } from "../Shared/Shared";
import Wrapup from "../Wrapup/Wrapup";
import './FinalWrapup.css';

import {FaUndoAlt} from 'react-icons/fa'

const FinalWrapup = () => {

  const totals = PeriodsPool.totals

  useEffect(() => {
    AppState.sendScores(true)
  }, []);


  return(
    <div className="FinalWrapup">
      <Header>
        <div className="line1">
          <AppTitle />
          {!AppState.isMultiplayer &&
          <div className="btn-right" style={{flexGrow: 1}}>
            <span>{Texts.get('restart-button')}</span>
            <IconButton onClick={() => AppState.restart()}><FaUndoAlt /></IconButton>
          </div>}
        </div>
      </Header>

      <div className="hero">
        <Wrapup period={totals} isScreen={false} />
      </div>

      {PeriodsPool.all.map(period => <Wrapup key={period.id} period={period} isScreen={false} />)}


    </div>
  )
}



export default observer(FinalWrapup)
