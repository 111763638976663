export const MeetingState = {
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  STOP: "STOP",
  FINISHED: "FINISHED",
};

export default class MeetingController {

  vertxClient = null
  meetingId = null
  isListening = false

  listeners = {};
  updateProgressListener = [];
  onScoreListener = [];

  MeetingState = MeetingState.FINISHED;
  MeetingCurrentProgress = 0;
  MeetingCurrentTime = 0;
  MeetingDuration = 0;
  MeetingSpeed = 0;

  IntervalProgress = null;

  /**
  * @param {vertxClient} vertxClient The Vertx client librairie
  * @param {String} meetingId The meeting Id
  */
  constructor(vertxClient, meetingId) {
    this.vertxClient = vertxClient
    this.meetingId = meetingId

    this.vertxClient.SIG_MeetingMessage.Add(this.handleMeetingMessage.bind(this));
  }

  UpdateProgress () {
    if (this.MeetingDuration === 0) return;
    let currentSec = this.MeetingDuration * this.MeetingCurrentProgress;

    if (this.MeetingState === MeetingState.PLAY) {
      currentSec = currentSec + 0.2 * this.MeetingSpeed;
      const newProgress = currentSec / this.MeetingDuration;
      this.MeetingCurrentProgress = newProgress;
    }
    this.MeetingCurrentTime = currentSec;
    this.updateProgressListener.forEach((cb) => {
      cb(this.MeetingCurrentProgress,this.MeetingCurrentTime,this.MeetingDuration)
    })
  }

  InitIntervalUpdateProgress() {
    if (this.IntervalProgress === null) {
      this.IntervalProgress = setInterval(this.UpdateProgress.bind(this), 200);
    }
  }

  ClearListener () {
    if (this.IntervalProgress !== null) {
      clearInterval(this.IntervalProgress);
      this.updateProgressListener = [];
    }
    if (this.isListening !== false) {
      this.vertxClient.SIG_MeetingMessage.Remove(this.handleMeetingMessage);
      this.isListening = false;
      this.onScoreListener = false;
    }
  }

  SendCmd (cmd, cb) {
    this.vertxClient.UserMeetingSequencerCommand(this.meetingId, cmd, cb)
  }



  UpdateProgressFromEvent(data) {
    if (data.Event !== undefined) {
      switch (data.Event.Type) {
        case "PERIOD_STARTED":
        this.MeetingState = MeetingState.PLAY;
        this.MeetingCurrentProgress = data.Event.PeriodProgress;
        this.MeetingDuration = data.Event.PeriodDurationSec;
        this.MeetingSpeed = data.Event.PeriodSpeedFactor;
        break;

        case "PERIOD_PAUSED":
        this.MeetingState = MeetingState.PAUSE;
        this.MeetingCurrentProgress = data.Event.PeriodProgress;
        if (data.Event.PeriodDurationSec !== undefined)
        this.MeetingDuration = data.Event.PeriodDurationSec;
        break;

        case "PERIOD_STOPPED" || "PERIOD_FINISHED":
        this.MeetingState = MeetingState.PAUSE;
        this.MeetingCurrentProgress = 0;
        this.MeetingCurrentTime = 0;
        this.MeetingDuration = 0;
        this.MeetingSpeed = 0;
        this.getMeetingInfos(() => {});
        break;

        case "SEQUENCE_FINISHED":
        this.MeetingState = MeetingState.FINISHED;
        this.MeetingCurrentProgress = 0;
        this.MeetingCurrentTime = 0;
        this.MeetingDuration = 0;
        this.MeetingSpeed = 0;
        this.getMeetingInfos(() => {});
        break;

        default:
        break;
      }
    }

    if (data.Info !== undefined) {
      switch (data.Info.Type) {
        case "SEQUENCE_INFO":
        if (data.Info.Sequence.CurrentPeriod !== null) {
          if (data.Info.Sequence.CurrentPeriod !== -1) {
            this.MeetingDuration = data.Info.Sequence.Periods[data.Info.Sequence.CurrentPeriod].DurationSec;
            this.MeetingCurrentProgress = data.Info.Sequence.Progress;

          } else {
            this.MeetingDuration = 0;
            this.MeetingCurrentProgress = 1;
          }

          switch (data.Info.Sequence.State) {
            case "PERIOD_STARTED":
            this.MeetingState = MeetingState.PLAY;
            break;

            case "PERIOD_PAUSED" || "PERIOD_STOPPED" || "PERIOD_FINISHED":
            this.MeetingState = MeetingState.PAUSE;
            break;

            case "SEQUENCE_FINISHED":
            this.MeetingState = MeetingState.FINISHED;
            break;

            default:
            break;
          }
        }
        break;
        case "STATUS":
        this.MeetingCurrentProgress = data.Info.PeriodProgress;
        this.MeetingSpeed = data.Info.SpeedFactor
        break;

        default:
        break;
      }
    }
  }

  handleMeetingMessage(meeting_id, from_user_id, msg){
    const data = JSON.parse(msg);

    if (meeting_id === this.meetingId && data !== null) {

      if ( data.Data !== undefined && data.Name === "Sequencer") {

        const sequencer = JSON.parse(data.Data);
        // console.log("MESSAGE", meeting_id, sequencer)
        let key = sequencer.Event ? "Event" : sequencer.Info ? "Info" : null
        if(key) {
          Object.keys(this.listeners).forEach((EventName) => {
            if (EventName === sequencer[key].Type) {
              this.listeners[EventName].forEach((cb) => {
                cb(sequencer);
              });
            }
          });
        }
        this.UpdateProgressFromEvent(sequencer);

        if (sequencer.Status) {
          this.MeetingCurrentProgress = sequencer.Status.PeriodProgress;
          this.MeetingState = MeetingState.PLAY;
        }
      }

      if (data.Name === "Score") {
        this.onScoreListener.forEach((cb) => {
          cb(from_user_id, data.Data);
        })
      }
    }
  }

  /**
  * Connect the user to the meeting for receiving events after that
  *
  * @param {Function} cb
  */
  connectToMeeting(cb) {
    if (this.vertxClient !== null) {
      this.deleteAllListeners();
      this.vertxClient.UserMeetingConnect( this.meetingId, (res, status, data) => {
        cb(res, status, data);
      })
    }
  }

  /**
  * Start or play the period
  *
  * @param {Function} cb
  */
  play(cb) {
    if (this.vertxClient !== null) {
      // const cmd = {StartPeriod: 0};
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.StartPeriod()
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Pause the current period
  * @param {Function} cb
  */
  pause(cb) {
    if (this.vertxClient !== null) {

      const cmd = this.vertxClient.sequencerCmdHelper.__class__.PausePeriod()
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Set the sequence to play, the sequence is inside the Config/Sequence.js file
  * @param {Function} cb
  */
  // setSequence(sequence, cb) {
  //     if (this.GmsClient !== null) {

  //         if (sequence === undefined)
  //             sequence = Sequences;
  //         this.GmsClient.MeetingSequenceSet(
  //             this.Meeting,
  //             JSON.stringify(sequence),
  //             cb
  //         );
  //     }
  // }

  /**
  * Stop the current sequence
  * @param {Function} cb
  */
  stop(cb) {
    if (this.vertxClient !== null) {
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.StopSequence()
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Set the current period of the meeting
  * @param {Number} period
  * @param {Function} cb
  */
  setPeriod(period, cb) {
    if (this.vertxClient !== null) {
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.SetPeriod(period)
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Stop the current period
  * @param {Function} cb
  */
  stopPeriod(cb) {
    if (this.vertxClient !== null) {
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.StopPeriod();
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Update the speed of the current period
  * @param {Number} speed
  * @param {Function} cb
  */
  updateSpeed(speed, cb) {
    if (this.vertxClient !== null) {
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.UpdateSpeedFactor(speed)
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Force the serveur to send a meeting info throught the EventListener
  *
  * @param {Function} cb
  */
  getMeetingInfos(cb) {
    if (this.vertxClient !== null) {
      const cmd = this.vertxClient.sequencerCmdHelper.__class__.GetInfos();
      this.SendCmd(cmd, cb);
    }
  }

  /**
  * Remove all the listeners in the class and delete listener for the VertxClient
  */
  deleteAllListeners() {
    this.ClearListener();
    this.listeners = {};
    this.updateProgressListener = [];
    this.onScoreListener = [];
  }

  /**
  * Remove all the listeners in the update listener
  */
  deleteProgressUpdateListener() {
    this.updateProgressListener = [];
  }

  /**
  * Remove listener with the callback and delete listener for the VertxClient
  * @param {String} eventType Type of event for example : "PERIOD_STARTED"
  * @param {Function} cb Ref of the function where the event is sent
  */
  deleteListener(eventType, cb) {
    if (this.listeners[eventType] === undefined) return;
    for (var i = 0; i < this.listeners[eventType].length; i++) {
      if (this.listeners[eventType][i] === cb) {
        this.listeners[eventType].splice(i, 1);
        return;
      }
    }
  }

  /**
  * Add an event listener to the current connected meeting
  *
  * @param {String} eventType Type of event for example : "PERIOD_STARTED"
  * @param {Function} cb Callback function where the event will be sent
  */
  addEventListener(eventType, cb) {
    if (cb === null) return;
    if (this.listeners[eventType] === undefined) this.listeners[eventType] = []
    this.listeners[eventType].push(cb);
    // this.printListeners()
  }

  /**
  * Add a PeriodStarted event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnPeriodStartedListener(cb) {
    console.log("adding addOnPeriodStartedListener")
    this.addEventListener("PERIOD_STARTED", cb);
  }

  /**
  * Add a PeriodPaused event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnPeriodPausedListener(cb) {
    this.addEventListener("PERIOD_PAUSED", cb);
  }

  /**
  * Add a PeriodStopped event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnPeriodStoppedListener(cb) {
    this.addEventListener("PERIOD_STOPPED", cb);
  }

  /**
  * Add a PeriodFinished event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnPeriodFinishedListener(cb) {
    this.addEventListener("PERIOD_FINISHED", cb);
  }

  /**
  * Add a PeriodChanged event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnPeriodChangedListener(cb) {
    this.addEventListener("PERIOD_CHANGED", cb);
  }

  /**
  * Add a SequenceFinished event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnSequenceFinishedListener(cb) {
    this.addEventListener("SEQUENCE_FINISHED", cb);
  }

  /**
  * Add a SequenceInfo event listener to the current connected meeting
  *
  * @param {Function} cb
  */
  addOnSequenceInfoListener(cb) {
    this.addEventListener("SEQUENCE_INFO", cb);
  }

  /**
  * Add a MeetingProgressUpdate event listener to return the progression current time in sec with the max duration
  *
  * @param {Function} cb will receive the percent on 1, the current time, the duration max
  */
  addOnMeetingProgressUpdate(cb) {
    this.InitIntervalUpdateProgress();
    this.updateProgressListener.push(cb);
    if (this.MeetingState === MeetingState.FINISHED)
    this.getMeetingInfos(() => {});
  }

  /**
  * Add a Score Event callback to listen
  *
  * @param {Function} cb
  */
  addOnScoreEvent(cb) {
    this.onScoreListener.push(cb);
  }

  /**
  * To debug, print the list of listeners
  */
  printListeners() {
    console.log("listeners : ", this.listeners, this.updateProgressListener, this.onScoreListener)
  }
}
