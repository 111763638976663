import React, { useEffect, useState } from 'react';
import { Tweenable } from 'shifty';
import './Shared.css';
import bcg_logo from '../../images/bcg_logo.png'
import Texts from '../../js/Texts';



export function pad(val) {
  return val.toString().padStart(2, "0")
}

export const Button = ({onClick, disabled, tip, style={}, className, children}) => {
  return (
    <button onClick={onClick} disabled={disabled} style={style} className={"btn " + className}>
      {tip && <div className="tip">{tip}</div>}
      {children}
    </button>
  );
};

export const IconButton = ({onClick, disabled, style={}, className, children}) => {
  return (
    <button onClick={onClick} disabled={disabled} style={style} className={"icon-btn " + className}>
      {children}
    </button>
  );
};


export const AnimatedNumber = ({duration=1000, value, formatValue=v=>v}) => {
  const [animatedValue, setAnimatedValue] = useState(value);

  useEffect(() => {
    let tweenable = new Tweenable()
    tweenable.setConfig({
      from: { x: animatedValue },
      to: { x: value },
      duration,
      easing: 'easeOutQuad',
      step: state => setAnimatedValue(state.x)
    });
    tweenable.tween().catch(err => {})

    return () => {
      tweenable.cancel()
    }
  }, [value])



  return (
    <span>
      {formatValue(animatedValue)}
    </span>
  )
}


export const AppTitle = () => {
  return (
  <div className="app-title">
    <img src={bcg_logo} alt="logo"/>
    {Texts.get("app_title")}
  </div>)
}