import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import AppState, { SCREENS } from '../../js/AppState';
import DataLoading from '../../js/DataLoading';
import { Button } from '../Shared/Shared';

import './Pdf.css'

const Pdf = ({showStartBtn = true}) => {

  const pdfData = useRef(null)

  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfCtnrRef = useRef(null)

  const [w, setW] = useState(0);
  const [h, setH] = useState(null);

  useEffect(() => {
    resizePdf()
    window.addEventListener('resize', resizePdf)

    return () => {
      window.removeEventListener('resize', resizePdf)
    }

  },[pdfCtnrRef.current])


  function resizePdf() {

    if(!pdfData.current) return
    let pdfRatio = pdfData.current.originalHeight / pdfData.current.originalWidth
    let pageWidth = pdfCtnrRef.current.offsetWidth
    let pageHeight = pdfCtnrRef.current.offsetHeight


    // 1// on essaie via width
    let futureH = pageWidth * pdfRatio


    if (futureH < pageHeight - 100) {
      let newW = Math.max(600, pageWidth)
      setW(newW)
      setH(null)
      // console.log("set by width")
    } else {
      // console.log("set by height")
      let newH = Math.max(300, pageHeight - 100)
      setW(null)
      setH(newH)
    }
  }


  function onDocumentLoadSuccess(data) {
    // console.log("onDocumentLoadSuccess", data)
    let {numPages} = data
    setTotalPages(numPages);
  }

  function handleError(error) {
    AppState.noPDF = true
    AppState.setScreen(SCREENS.GAME)
  }

  function getInfo(data) {
    if(pdfData.current) return
    pdfData.current = data
    resizePdf()
  }

  return (
    <div className="Pdf" ref={pdfCtnrRef}>
      <div className="pdfCtnr">
        <Document file={DataLoading.files.pdf} onSourceError={handleError} onLoadError={handleError} onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={w} height={h} onLoadSuccess={getInfo} pageNumber={pageNumber} />
        </Document>
      </div>

      <div className="pdf-controls">
        {pageNumber > 1 && <Button onClick={() => setPageNumber(n => n - 1)}>Prev</Button>}
        {pageNumber < totalPages && <Button onClick={() => setPageNumber(n => n + 1)}>Next</Button>}
        {pageNumber === totalPages && showStartBtn && <Button onClick={() => AppState.setScreen(SCREENS.GAME)}>Start</Button>}
      </div>
    </div>
  );
};

export default Pdf;
