import React from 'react';
import './LoadingScreen.css';


const LoadingScreen = () => {
  // const [state, setState] = useState(null);

  return (
    <div className="LoadingScreen">
      Loading...
    </div>
  );
};

export default LoadingScreen;
