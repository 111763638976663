import marked from 'marked'
import mustache from 'mustache'

class Texts {
  all = {}

  init(texts) {

    texts.forEach(t => {
      if(t.id) {
        if(this.all[t.id] !== undefined) {
          console.warn("Overwriting text (" + t.id + ")")
        }
        this.all[t.id] = t.text
      }
    })
  }

  get(key, parseMarkdown = false, markdownOptions = {}, mustacheVars = null) {
    let t = this.all[key]
    if(t !== undefined) {
      if(mustacheVars) t = mustache.render(t, mustacheVars)
      if(parseMarkdown) {
        let opts = { linkTargetBlank: true, ...markdownOptions}
        const renderer = new marked.Renderer();
        if(opts.linkTargetBlank) {
          const linkRenderer = renderer.link;
          renderer.link = (href, title, text) => linkRenderer.call(renderer, href, title, text).replace(/^<a /, '<a target="_blank" rel="nofollow" ') }
        if(opts.noParagraph) { renderer.paragraph = text => text + '\n' }
        return marked(t, {renderer})
      }

      return t
    }
    console.warn("The key", key, "doesn't exist in textes.data")

    return key

  }


}


export default new Texts()