import CardsPool from "./CardsPool"
import PeriodsPool from "./PeriodsPool"

class Combination {
  constructor(json) {
    Object.assign(this, json)
    this.cards_ids = this.cards_ids.split(";")
  }

  isActive(card_id, other_bought_cards) {
    let active = false

    if(this.cards_ids.includes(card_id)) {
      let intersection = other_bought_cards.filter(value => this.cards_ids.includes(value))
      if(intersection.length > 0) active = true
    }

    return active
  }

  get prettyValue() {
    return (this.value > 0 ? "+" : "") + this.value
  }

}


class Combinations {
  all = []

  init(json) {
    this.all = json
    .filter(d => d.cards_ids !== null)
    .map(d => new Combination(d))
  }

  get currentCombinations() {

    // analyse les cartes achetées, et regarde pour chacune d'elles
    // si elle subit une combi ou pas

    // ce petit algo pour récupérer les cartes dans l'ordre de l'achat
    let allboughtcards_ids = []
    PeriodsPool.all.forEach( p => {
      allboughtcards_ids = [...allboughtcards_ids, ...p.boughtCards.map(c => c.id)]
    })


    // on ne teste qu'avec les cartes précédentes, afin que la première ne subisse pas la combinaison
    let done_ids = []

    let combis = allboughtcards_ids.reduce((acc, id) => {
      let card_combis = this.getCombinations(id, done_ids)
      done_ids.push(id)

      if(card_combis) acc[id] = card_combis

      return acc
    }, {})
    return combis
  }


  getCombinations(card_id, othercards) {
    // console.log("getCombination", card_id, othercards)

    let c = this.all.filter(combination => {
      return combination.isActive(card_id, othercards)
    })
    return c.length > 0 ? c : null

  }



}





export default new Combinations()