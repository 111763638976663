import React, { useState, useEffect } from 'react'
import Texts from '../../../js/Texts';

import './CardDetail.css'

const CardDetail = ({data, showValue=false}) => {

  let {value, id, color} = data

  let angle = value * 90
  return (
    <div className="CardDetail" >
      <div className="CardDetail-fills" style={{"--angle": angle+"deg"}}>
        {showValue && <div className="CardDetail-value">{Math.round(value * 10) / 10}</div>}
      </div>
      <div className="detail-name">{Texts.get("card-"+id)}</div>
    </div>
  );
};

export default CardDetail;
