import { observer } from "mobx-react-lite";
import React from "react";
import PeriodsPool from "../../js/PeriodsPool";
import Texts from "../../js/Texts";
import { AnimatedNumber, AppTitle, Button, IconButton, pad } from "../Shared/Shared";
import './ReleaseHeader.css';

import {FaClock, FaQuestion} from 'react-icons/fa'
import AppState from "../../js/AppState";


const secsToMinSecs = (total_secs) => {
  let min = Math.floor(total_secs / 60)
  let secs = total_secs % 60
  return {min, secs}
}

const ReleaseHeader = () => {
  const period = PeriodsPool.currentPeriod
  const percentage = 100 - (period.complexity * 100 / period.max_complexity)

  const pS = {transform: `translateX(-${percentage}%)`}
  const tL = PeriodsPool.currentPeriod.ticks

  let period_duration = secsToMinSecs(PeriodsPool.currentPeriod.duration_seconds)

  let time_elapsed = secsToMinSecs(PeriodsPool.currentPeriod.duration_seconds - tL)

  const warning = tL < 60

  function finishPeriod() {
    PeriodsPool.currentPeriod.stop()
  }

  return (
  <div className="ReleaseHeader">
    <div className="line1">
      <h2>{period.title}</h2>
      <AppTitle />
      {!AppState.noPDF && <IconButton className="help-button" onClick={()=>{AppState.showPdfPopup = true}}>
        <FaQuestion />
      </IconButton>}
    </div>

    <div className="line2">

      <div className={"countdown" + (warning ? " warning" : "")}>
        <FaClock />
        {pad(time_elapsed.min)}:{pad(time_elapsed.secs)} / {pad(period_duration.min)}:{pad(period_duration.secs)}


      </div>

      <div className="progress-ctnr">
        <div className="progress">
          <div className="progress-total">{period.max_complexity}</div>
          <div className="progress-bar" style={pS}>
            <div className="progress-current">
              <AnimatedNumber duration={2000} value={period.complexity} formatValue={v => v.toFixed(0)} />
            </div>
          </div>
        </div>
        <div className="progress-title">{Texts.get("complexity_progressbar")}</div>
      </div>

      <div className="end-release">
        {/* seulement si period en "play" */}
        {PeriodsPool.currentPeriodStarted &&
        <Button  onClick={finishPeriod}>
          {Texts.get("finish_period_button")}
        </Button>}

      </div>
    </div>





  </div>)
}

export default observer(ReleaseHeader)
