import React, { useEffect, useRef, useState } from 'react';

import Header from '../Header/Header';
import Pdf from '../Pdf/Pdf';
import './StartScreen.css';

const StartScreen = () => {
  return (
    <div className="StartScreen">
      <Header />
      <Pdf/>
    </div>
  );
};

export default StartScreen;
