import { observer } from 'mobx-react-lite'
import React from 'react'
import PeriodsPool from '../../js/PeriodsPool'
import { Button, IconButton } from '../Shared/Shared'
import {FaLock, FaBan, FaCheckCircle, FaPlus, FaTimes} from 'react-icons/fa'
import Texts from '../../js/Texts'

import './PickButton.css'
import { CARDS_STATE } from '../../js/Card'

const PickButton = ({card}) => {

  if(card.state === CARDS_STATE.LOCKED) { return (
    <div className="PickButton PickButton-text">
      <FaLock className="icon" />
      {Texts.get("locked", false, {}, { cardsNum: card.prerequisites.join(',') })}
    </div>
  )}


  if(card.state === CARDS_STATE.TOO_COMPLEX) { return (
    <div className="PickButton PickButton-text">
      <FaBan className="icon" />
      {Texts.get("complexity_too_high")}
    </div>
  )}

  if(card.state === CARDS_STATE.PICKABLE) { return (
    <IconButton className="big" onClick={() => card.pick() }>
      <FaPlus />
    </IconButton>
  )}


  if(card.state === CARDS_STATE.PICKED_NOW) { return (
    <IconButton className="big whitered" onClick={() => card.release() }>
      <FaTimes />
    </IconButton>
  )}

  if(card.state === CARDS_STATE.PICKED_PREV) { return (
    <div className="PickButton PickButton-text">
      <FaCheckCircle className="icon" />
      {Texts.get("selected_previous_release")}
    </div>
  )}

}

export default observer(PickButton)
