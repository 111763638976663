import { makeAutoObservable } from "mobx"
import AppState, { SCREENS } from "./AppState"
import CardsPool from "./CardsPool"
import Combinations from "./Combinations"
import Texts from "./Texts"

export const PERIOD_STATE = {
  NOT_STARTED : "Not started",
  STARTED : "Started",
  PAUSED  : "Paused",
  FINISHED : "Finished"
}

class Period {

  ticks = 0 //

  state = PERIOD_STATE.NOT_STARTED

  constructor(json, index) {
    makeAutoObservable(this)
    Object.assign(this, json)
    this.index = index


    this.max_complexity = parseInt(this.max_complexity)


    this.setTicks(parseInt(this.duration_seconds))
  }
  reinit() {
    this.state = PERIOD_STATE.NOT_STARTED
    this.setTicks(0)
  }
  start() {
    this.state = PERIOD_STATE.STARTED
    if (!AppState.isMultiplayer) {
      this.interval = setInterval(() => this.onTick(), 1000)
    }
  }
  pause() {
    this.state = PERIOD_STATE.PAUSED
  }
  stop() {
    this.state = PERIOD_STATE.FINISHED
    if(this.interval) window.clearInterval(this.interval)
    AppState.setScreen(SCREENS.WRAPUP)
  }

  onTick(ticksvalue) {
    if(ticksvalue) {
      this.setTicks(ticksvalue)
    } else {
      this.setTicks(this.ticks - 1)
    }

    if(this.ticks === 0) {
      this.stop() // ERROR !!! (???)
    }
  }

  setTicks(t) {
    this.ticks = t
  }

  get boughtCards() {
    return CardsPool.boughtCards.filter(c => c.picked === this.id)
  }

  get complexity() {
    const val = this.boughtCards.reduce((acc, card) => acc + card.complexity, 0)
    return val
  }
  get impact() {
    const val = this.boughtCards.reduce((acc, card) => acc + card.pickedImpact, 0)
    return val
  }

  get combinationValue() {
    const val = this.boughtCards.reduce((acc, card) => acc + card.combinationValue, 0)
    return val
  }

  get totalImpact() {
    const val = this.impact + this.combinationValue
    return val
  }


  getCardsTotal(key) {
    const val = this.boughtCards.reduce((acc, card) => acc + card[key], 0)
    return val  / this.boughtCards.length

  }

  get scores(){
    return {
      state: this.state,
      complexity: this.complexity,
      nb_cards: this.boughtCards.length,
      impact: this.impact,
      discount: this.combinationValue,
      totalImpact: this.totalImpact,
    }
  }
}

class PeriodsPool {
  all = []

  currentPeriodIndex = 0

  constructor() {
    makeAutoObservable(this)
  }

  init(periods) {
    this.all = periods
    .filter(r => r.id !== null)
    .map((r, index) => new Period(r, index))

    this.currentPeriodIndex = 0
  }


  prev() { this.currentPeriodIndex-- }
  next() { this.currentPeriodIndex++ }

  updatePeriodsDuration(periodsConfig) {
    periodsConfig.forEach((config, index) => {
      this.all[index].setTicks(config.DurationSec)
    })
  }

  get onLastPeriod() {
    return this.currentPeriodIndex + 1 === this.all.length
  }

  get currentPeriod() {
    return this.all[this.currentPeriodIndex]
  }
  get currentPeriodStarted() {
    return this.currentPeriod.state === PERIOD_STATE.STARTED
  }
  get currentPeriodFinished() {
    return this.currentPeriod.state === PERIOD_STATE.FINISHED
  }

  get currentDuration() {
    return this.currentPeriod["duration-seconds"]
  }

  get(id) {
    return this.all.find(p => p.id === id)
  }

  get totalsScores() {
    return {
      state: this.currentPeriod.state,
      complexity: this.totals.complexity,
      nb_cards: CardsPool.boughtCards.length,
      impact: this.totals.impact,
      discount: this.totals.combinationValue,
      totalImpact: this.totals.totalImpact,
    }
  }

  get totals() {
    let totals = {
      title: Texts.get("final-wrapup-title"),
      complexity: 0,
      max_complexity: 0,
      impact: 0,
      combinationValue: 0,
      totalImpact: 0,
      getCardsTotal: (k) => {
        let val = 0
        this.all.forEach(period => {
          val +=  period.getCardsTotal(k)
        })
        return val / this.all.length
      }
    }

    this.all.forEach(period => {
      Object.keys(totals).forEach(k => {
        if(typeof totals[k] === "number") totals[k] += period[k]
        // if(typeof totals[k] === null) totals[k] += period.getCardsTotal(k)
      })



      // totals.deductions = [...totals.deductions, ...period.deductions]
    })

    return totals
  }

  setPeriodIndex(i) {
    this.currentPeriodIndex = i
  }

  // TODO remove
  reset() {
    this.currentPeriodIndex = 0
  }

}



export default new PeriodsPool()
