import AppState, { SCREENS } from "./AppState";
import CardsPool from "./CardsPool";
import MeetingController from "./MeetingController";
import PeriodsPool, { PERIOD_STATE } from "./PeriodsPool";


export const USER_STATES = {
  LOADING: "LOADING",
  USER_CONNECTION_SUCCESS: "USER_CONNECTION_SUCCESS",
  USER_CONNECTION_ERROR: "USER_CONNECTION_ERROR",
}

export const MEETING_STATES = {
  NOT_CONNECTED: "NOT_CONNECTED",
  CONNECTING: "CONNECTING",
  MEETING_CONNECTION_ERROR: "MEETING_CONNECTION_ERROR",
  MEETING_CONNECTION_SUCCESS: "MEETING_CONNECTION_SUCCESS",
  MEETING_WRONG_CODE: "MEETING_WRONG_CODE"
}

let VC = window.gms.VertxClient

// console.log("VC", VC)
class VertxConnection {
  client = null

  user_state = USER_STATES.LOADING
  meeting_state = MEETING_STATES.NOT_CONNECTED

  user_login = null
  connectedUser = null

  meeting_id = null

  meetingController = null;

  constructor() {
    this.onMessageReceived = this.onMessageReceived.bind(this)
    this.lastTick = 0;


    // BINDINGS
    this.handleStart = this.handleStart.bind(this)
    this.handlePause = this.handlePause.bind(this)
    this.handleChanged = this.handleChanged.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.handleFinished = this.handleFinished.bind(this)
    this.handleSequenceFinished = this.handleSequenceFinished.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleSequenceInfo = this.handleSequenceInfo.bind(this)
  }

  init() {
    let {ServerIP, ServerPort} = window.CONFIG.vertexConfig
    this.client = VC.CreateVertxClient( ServerIP, ServerPort );


    // this.client.SIG_MeetingMessage.Add(this.onMessageReceived);
  }

  onMessageReceived(meeting_id,from_user_id, message) {
    try {
      const m = JSON.parse(message);
      // ce ne sert à rien pour le moment

    } catch(err) {
      console.log("err", err)
    }
  }

  _debug_delete_user() {
    let { LocalStorageUserKey } = window.CONFIG.vertexConfig
    localStorage.removeItem(LocalStorageUserKey)
  }

  reconnectOrNewUser(cb) {
    let { Universe, App, LocalStorageUserKey } = window.CONFIG.vertexConfig
    let prev_user

    try {
      prev_user = JSON.parse( localStorage.getItem(LocalStorageUserKey) )
    } catch(e) {
      console.log("error parsing", LocalStorageUserKey, "from localstorage")
    }

    if(prev_user) {
      console.log("USING PREVIOUS ANONYMOUS USER")
      const {UserLogin, UserPassword} = prev_user
      this.client.UserConnect(Universe, App, UserLogin, UserPassword, (success, statusMessage, data) => {
        if (!success) {
          alert("Could not connect to Server !", statusMessage)
        } else {
          console.log("CONNECTED TO SERVER")
          this.connectedUser = data
          cb()
        }
      })

    } else {
      console.log("NEW ANONYMOUS USER ---")
      this.client.UserAnonymousConnectAdd(Universe, App, (success, statusMessage, data) => {
        console.log("(success, statusMessage, data)", success, statusMessage, data)
        if (!success) {
          alert("Could not connect to Server !", statusMessage)
        } else {

          this.connectedUser = data
          try{
            localStorage.setItem(LocalStorageUserKey, JSON.stringify(data))
          } catch(err) {
            console.log(err)
          }

          cb()
        }
      })
    }
  }




  setUserPseudoAndConnectToMeeting(pseudo, code, cb) {
    // console.log("this.connectedUser", this.connectedUser)
    this.client.UserUpdateInfo({Pseudo: pseudo}, (success, msg, data) => {
      if(success) {
        this.client.MeetingAddUserWithCode(code, this.connectedUser.UserLogin, (meetingAddSuccess, msg2, meetingData) => {
          if(meetingAddSuccess) {
            this.meeting_id = meetingData.MeetingID
            // console.log("FROM VTXCNXION this.meeting_id", this.meeting_id)


            this.client.UserMeetingConnect(this.meeting_id, (meetingConnectionSuccess, msg3, meetingConnectionData ) => {
              if(meetingConnectionSuccess) {

                this.meeting_state = MEETING_STATES.MEETING_CONNECTION_SUCCESS
                // ici on récupère la config du meeting
                this.client.UserMeetingGetConfig(this.meeting_id, (success, statusMsg, data)=>{
                  const config = VC.GetJsonObject(data)
                  // console.log("meeting config", config)
                  cb(true, config)
                })
              } else {
                this.meeting_state = MEETING_STATES.MEETING_CONNECTION_ERROR
              }
            });
          } else {
            console.log("erreur connection meeting",msg2, this.user_login)
            this.meeting_state = MEETING_STATES.MEETING_WRONG_CODE
            cb(false)
          }

        })

      } else {
        console.log("error UserUpdateInfo")
        this.meeting_state = MEETING_STATES.MEETING_CONNECTION_ERROR
        cb(false)
      }

    })

  }


  sendScores(scores) {
    this.sendMessage("Score", scores)


  }


  saveGame(savegame) {
    console.log("saving data", savegame)
    // savegame = {message: "this is a savegame"}


    this.client.UserUpdateCustomData(JSON.stringify(savegame), (success, statusMessage, data) => {
      console.log("success", success)
    })
  }

  sendMessage(name, data) {
    if(!this.meeting_id) return
    const msg = { Name: name, Data: data }

    this.client.UserMeetingSendToAll(this.meeting_id, JSON.stringify(msg), (success, msg, data)=> {

    })
  }


  initMeetingController (meetingId) {
    this.deleteMeetingController();
    this.meetingController = new MeetingController(this.client, meetingId);

    this.meetingController.addOnPeriodStartedListener(this.handleStart)
    this.meetingController.addOnPeriodPausedListener(this.handlePause)
    this.meetingController.addOnPeriodChangedListener(this.handleChanged)
    this.meetingController.addOnPeriodStoppedListener(this.handleStop)
    this.meetingController.addOnPeriodFinishedListener(this.handleFinished)
    this.meetingController.addOnSequenceFinishedListener(this.handleSequenceFinished)
    this.meetingController.addOnMeetingProgressUpdate(this.handleProgress)
    this.meetingController.addOnSequenceInfoListener(this.handleSequenceInfo)
  }


  handleProgress (percent, currentTime, maxTime) {


    let remaining = maxTime - Math.floor(currentTime)
    PeriodsPool.currentPeriod.onTick(remaining)
  }

  // si clic sur reset
  // {Id: null, Periods: null, Progress: 0, CurrentPeriod: -1, State: "SEQUENCE_FINISHED"}



  handleSequenceInfo(EventGms) {
    let Sequence = EventGms.Info.Sequence
    console.log("handleSequenceInfo", Sequence, Sequence.State)
    if (!Sequence) return

    // if(Sequence.CurrentPeriod >= 0) {
    //   PeriodsPool.setPeriodIndex(Sequence.CurrentPeriod)
    // }

    if (Sequence.State === "SEQUENCE_INITIALIZED") {
      this.handleStart(null)
      this.handlePause()
    }
    if (Sequence.State === "PERIOD_FINISHED") {this.handleStop()}

    if (Sequence.State === "PERIOD_STOPPED") this.handleStop()
    if (Sequence.State === "PERIOD_STARTED") {
      PeriodsPool.setPeriodIndex(Sequence.CurrentPeriod)
      this.handleStart(null)
    }
    if (Sequence.State === "PERIOD_PAUSED") this.handlePause()
    if (Sequence.State === "SEQUENCE_FINISHED") this.handleSequenceFinished(Sequence)
  }

  handleStart(sequencer) {
    console.log("handleStart")

    if (sequencer) {

      // le temps est > 0 => c'était une pause, pas un start
      const unpauseEvent = sequencer.Event.PeriodProgress > 0

      // si on est en Wrapup, on ne veut pas "unpause" la période qu'on a choisi de finir
      if(PeriodsPool.currentPeriodFinished && unpauseEvent) return

      // ici on a restart le jeu, il faut le remettre à 0
      const newGameEvent = sequencer.Event.Period === 0 && sequencer.Event.PeriodProgress === 0
      if(newGameEvent) CardsPool.releaseAll()


      PeriodsPool.setPeriodIndex(sequencer.Event.Period) // numero de la période
      let totaltime = sequencer.Event.PeriodDurationSec
      let elapsed = Math.floor(sequencer.Event.PeriodDurationSec * sequencer.Event.PeriodProgress)
      PeriodsPool.currentPeriod.setTicks(totaltime - elapsed)


    }
    AppState.isMultiplayer = true
    if(AppState.screen !== SCREENS.START) AppState.setScreen(SCREENS.GAME)

    PeriodsPool.currentPeriod.start()



  }
  handlePause() {
    console.log("handlePause")
    if(PeriodsPool.currentPeriodFinished) return
    PeriodsPool.currentPeriod.pause()
  }
  handleFinished (sequencer) {
    console.log("handleFinished")
    if (sequencer) {
      // AppState.ticks = sequencer.Event.PeriodDurationSec === undefined ? 0 : sequencer.Event.PeriodDurationSec;
      // AppState.periodTime = sequencer.Event.PeriodDurationSec;
    }

  }
  handleChanged (sequencer) {
    console.log("handleChanged", sequencer)
    if (sequencer) {
      // on passe à la bonne période
      // PeriodsPool.setPeriodIndex(sequencer.Event.Period) // numero de la période
      // AppState.setScreen(SCREENS.GAME)

    }
  }
  handleStop () {
    console.log("handleStop")
    AppState.setScreen(SCREENS.WRAPUP)
  }

  handleSequenceFinished (sequence) {
    console.log("handleSequenceFinished", sequence)
    // GO TO LAST PERIOD END

    // if(PeriodsPool.onLastPeriod && AppState.screen ===SCREENS.WRAPUP) {
    //   AppState.setScreen(SCREENS.FINALWRAPUP)
    // }
  }


  deleteMeetingController () {
    if (this.meetingController !== null) {
      this.meetingController.deleteAllListeners();
      this.meetingController = null;
    }
    this.lastTick = 0;
  }



}

export default new VertxConnection()