import React from 'react';
import Card from '../Card/Card';
import './CardsList.css';



const CardsList = ({cards, showCombinations=false}) => {

  const colsNb = Math.min(3, cards.length)




  return (
    <div className={"CardsList" + (cards.length > 1 ? " multiple-cards" : "")} style={{gridTemplateColumns: `repeat(${colsNb}, 1fr)`}}>
      {cards.map(card => <Card card={card} key={card.id} showCombinations={showCombinations} />)}
    </div>
  );
};

export default CardsList;
