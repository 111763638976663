import React from "react";
import { IconButton } from "../Shared/Shared";
import './Popup.css';

import {FaTimes} from 'react-icons/fa'

const Popup = ({children, fullscreen=false, close}) => (
  <div className="Popup-overlay" onClick={close}>
    <div className={"Popup" + (fullscreen ? " fullscreen" : "")} onClick={e => e.stopPropagation()}>
      {close && <IconButton className="grey Popup-close" onClick={close}><FaTimes /></IconButton>}

      <div className="Popup-content">{children}</div>
    </div>

  </div>
);


export default Popup;
