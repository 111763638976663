import { autorun, makeAutoObservable, toJS } from "mobx";
import CardsPool from "./CardsPool";
import DataLoading from './DataLoading';
import Combinations from "./Combinations";
import PeriodsPool from "./PeriodsPool";
import Texts from "./Texts";
import VertxConnection from "./VertxConnection";


/**
 * Generic class handling various states for app behaviour
 */

function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}


export const SCREENS = {
  LOADING :"LOADING",
  LOGIN :"LOGIN",
  START :"START",
  GAME :"GAME",
  WRAPUP :"WRAPUP",
  FINALWRAPUP :"FINALWRAPUP",
}


class AppState {

  debugMode = false

  deck = null

  screen = SCREENS.LOADING
  showPdfPopup = false
  isMultiplayer = true

  constructor() {
    if(getUrlVar("debug") === "1") this.debugMode = true

    makeAutoObservable(this)


    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)
    .then(() => {
      Texts.init(DataLoading.files.texts)
      VertxConnection.init()


      window.document.title = Texts.get("app_title")



      // envoie auto les scores à chaque modif
      // TODO pas tres elegant
      autorun(() => this.sendScores())

      if(this.debugMode) {
        this.isMultiplayer = false
        this.screen = SCREENS.LOGIN
      } else {
        VertxConnection.reconnectOrNewUser(() => {
          this.screen = SCREENS.LOGIN
        })
      }

    })
    .catch(err => {
      console.log(err)
    })
  }

  setScreen(id) {
    this.screen = id

    if(id === SCREENS.GAME) {
      if(!this.isMultiplayer) PeriodsPool.currentPeriod.start()
    }
  }

  testCode(code, pseudo) {
    let soloCodeConfig = DataLoading.files.Codes
    .filter(soloconfig => soloconfig.code !== null)
    .find(soloconfig => code.toUpperCase() === soloconfig.code.toUpperCase())

    return new Promise( (resolve, reject) => {
      if(soloCodeConfig) {
        this.isMultiplayer = false
        this.loadDeck(soloCodeConfig.deck)
        .then( () => {
          if(this.debugMode) this.setScreen(SCREENS.GAME)
          else this.setScreen(SCREENS.START)
        })
        .catch(err => { console.log(err) })
      }

      else {
        VertxConnection.setUserPseudoAndConnectToMeeting(pseudo, code.toUpperCase(), (success, config) => {
          if(success) {
            this.loadDeck(config.deck)
            .then( () => {
              PeriodsPool.updatePeriodsDuration(config.MeetingPeriods)
              VertxConnection.initMeetingController(VertxConnection.meeting_id);
              this.setScreen(SCREENS.START)
            })
          } else {
            reject()
          }
        })
      }
    })
  }

  loadDeck(deck) {
    this.deck = deck

    return DataLoading
    .getAllFiles(window.CONFIG.deckFiles, window.CONFIG.root + deck + "/")
    .then( () => {
      PeriodsPool.init(DataLoading.files.periods)
      Combinations.init(DataLoading.files.combinations)
      CardsPool.init(DataLoading.files.cards)
      Texts.init(DataLoading.files.texts_deck)

      window.document.title = Texts.get("app_title")

    })

  }

  restart() {
    //reload les elements du deck précédent
    this.loadDeck(this.deck)
    .then( () => { this.setScreen(SCREENS.GAME) })
  }

  sendScores(totals = false) {
    // console.log("sending Scores")
    if(!PeriodsPool.currentPeriod) return

    // let scores = totals ?  PeriodsPool.totalsScores : PeriodsPool.currentPeriod.scores
    let scores =  PeriodsPool.totalsScores
    // console.log("scores", totals, scores)
    VertxConnection.sendScores(scores)


  }

}


export default new AppState()
