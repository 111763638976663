import { makeAutoObservable } from "mobx"
import Card from "./Card"



class CardsPool {
  all = []
  constructor() {
    makeAutoObservable(this)
  }
  init(cards) {
    this.all = cards
    .filter(c => c.id !== null)
    .map(c => new Card(c))
  }

  releaseAll() {
    this.all.forEach(c => c.release())
  }
  get(id) {
    return this.all.find(c => c.id === id)
  }

  get boughtCards() {
    return this.all.filter(c => c.picked !== false)
  }

  //
  get max_complexity() {
    return Math.max.apply(null, this.all.map(c => c.complexity))
  }

  get total_impact() {
    return Math.max.apply(null, this.all.map(c => c.pickedImpact + c.combinationValue))
  }

}


export default new CardsPool()
