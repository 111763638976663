import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { SCREENS } from "../../js/AppState";
import CardsPool from "../../js/CardsPool";
import Texts from "../../js/Texts";
import PickButton from "../PickButton/PickButton";
import './Card.css';
import CardDetail from "./CardDetail/CardDetail";

const CardValue = ({className = "", title, value, secondValue=null, maxValue=50}) => {
  let minWidthPercent = 10
  let percentCoef = 80 - minWidthPercent


  let width1 = value / maxValue * percentCoef + minWidthPercent

  let style = {
    width: Math.abs(secondValue / value * 100) + "%",
    right: 0,
    left: "auto"
  }

  let style2 = {
    width: Math.abs(secondValue / maxValue * 100) + "%",
    left: width1 + "%"
  }


  return (
    <div className={"card-value " + className}>
        <span  className="card-value-title">{title}</span>
        <div className="card-value-bar-ctnr" >
          <div className="card-value-bar" style={{width: width1 + "%"}}>
            <span  className="card-value-value">{value}</span>
            {secondValue && secondValue < 0 &&
            <div className="card-second-value-bar" style={style}>
              <span className="card-second-value-value" >{secondValue}</span>
            </div>}
          </div>
          {secondValue && secondValue > 0 &&
            <div className="card-second-value-bar" style={style2}>
              <span className="card-second-value-value" >{"+" + secondValue}</span>
            </div>}
        </div>
      </div>
  )
}


const Card = ({card, showCombinations=false}) => {

  let cl = "Card "

  if(AppState.screen === SCREENS.GAME) cl += card.state

  if(showCombinations) {
    cl += card.combinationValue < 0 ? "negative" : ""
    cl += card.combinationValue > 0 ? "positive" : ""
  }

  return (
  <div className={cl} >
    <div className="card-top">
      <h3 title={card.id} className="card-category">{card.categTitle}</h3>
      <h2 className="card-title">
        <span className="card-id">{card.id}</span>{card.title}</h2>
    </div>

    <p className="card-desc">{card.description}</p>

    <div className="card-content">
      <CardValue className="card-value-complexity" maxValue={CardsPool.max_complexity}  title={Texts.get("card_complexity")} value={card.complexity} />

      {showCombinations &&
      <>
        <CardValue className="card-value-impact"
        title={Texts.get("card_impact")}
        value={card.pickedImpact}
        maxValue={CardsPool.total_impact}
        secondValue={(showCombinations && card.combinationValue !== 0) ? card.combinationValue : null} />

        <div className="card-combinations">
          {card.combinations && card.combinations.map((c, index) => {
            let cl = "card-combination"
            cl += c.value > 0 ? " positive" : " negative"
            return <div key={index} className={cl}>{c.description} ({c.prettyValue})</div>
          })}

        </div>


        <div className="card-details" >
          {card.details.map(detail => {
            return <CardDetail data={detail} key={detail.id} />
          })}
        </div>

      </>}
    </div>

    {!showCombinations && <div className="card-pick-btn">
      <PickButton card={card} />
    </div>}

  </div>)
}



export default observer(Card)
