import React from 'react';
import Texts from '../../js/Texts';
import { AppTitle } from '../Shared/Shared';
import './Header.css';


const Header = ({children}) => {
  return (
  <div className="Header">
    {children}
    {!children && <AppTitle />}
  </div>)
};

export default Header;
